.frameValidation{
    width: 100%;
    box-sizing: border-box;
    height: calc(100vh - 7px);
    padding: 0px;
    /* margin-top: -1px; */
    border: 0px;
    margin: 0px;
    transition: none !important;
    z-index: -90;
}