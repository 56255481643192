@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  background-color: #ffffff00;
  z-index: 999;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  backface-visibility: hidden;
  justify-content: center;
  /* opacity: 1;
  transition: opacity 1s; */
}
.loading .message {
  font-size: x-large;
  color: #000;
}
.return-on-website{
  top:15px;
  left:15px;
  text-decoration: none;
  position: fixed;
}
.loading .spinner {
  z-index: 999;
  position: absolute;
  width: 70px;
  height: 100px;
  animation: spin 1s linear infinite;
  border: 8px solid #70f4f411;
  border-top: 8px solid #20CDCE;
  border-radius: 50%;
  display: none;
  background-color: #ffffff00;
}



.nomeOcchiale {
  position: absolute;
  top: 20%;
  right: 0;
  left: 0;
  text-align: center;
  z-index: -1;
}

.replayBtn {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 25px;
  margin: 30px;
}

@media (max-width: 640px) {
  .progress {
    font-size: 200px;
  }
}

@media (max-width: 820px) {
 
 .progress {
  font-size: 300px;
 }
}


.loaded .loading {
  /* opacity: 0; */
}

#lazy-load-poster{
  background-color: transparent !important;
}

.slot.poster{
  background-color: transparent !important;
}

.viewAction{
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  margin-top: 20px;
  z-index: 10;

}

.validationButton{
  border: none;
    text-decoration: underline;
    font-size: 1rem;
    font-weight: bold;
}

.animationButton{
  border: none;
  font-size: 1.4rem;
  font-weight: bold;
}

.animationContainer{
  position: absolute;
  display: flex;
  width: 100%;
  bottom: 100px;
  justify-content: center ;
}


.animation_button{
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  border-radius: 25px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  color: black;
  padding: 10px;
  z-index: 25;
  margin-top: 15px;
  bottom: 0px;
  transition: transform 0.8s ease;
}

.animation_button:hover{
  opacity: 0.7;
}

.clipped_icon_button{
  color: white;
  font-size: 28px;
  margin-right: 8px;
  width: 28px;
  height: 28px;
}


@media (max-width: 820px) {
  .animation_button{
    padding: 0px;
    border-radius: 0px;
  }
  /* .animationContainer{
    bottom: 50px;
  } */
}