.button {
  border: none;
  /* background-color: white; */
  position: relative;
  border-radius: 20px;
}

.buttonAR {
  border-radius: 5px;
  border-color: white;
  background-color: white;
  position: fixed;
  right: 0;
  top: 0;
  margin-top: 20px;
  margin-right: 20px;
}

.button-right-top {
  position: fixed;
  right: 0;
  top: 0;
  margin-top: 20px;
  margin-right: 20px;
  /* border-radius: 20px; */
}

.buttonARBottom {
  border: none;
  /* background-color: white; */
  border-radius: none;
  bottom: 10px;
  position: absolute;
  left: calc(50% - 20px );
}

.buttonARBottomWebView{
  top: 23px;
  position: absolute;
  /* -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%); */
  left: calc(50% - 50px);
  border: 1px solid #111;
  border-radius: 5px;
 width: 100px;
 text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  display: none;
}

.button-right-bottom {
     /* width: 100%; */
     position: absolute;
     left: calc(50% - 30px );
     bottom: 10px;
     border: none;

     /* margin-top: 20px; */
     /* margin-right: 20px; */
     /* border-radius: 20px;*/
}
