.canvas29985 {
  width: 100%;
  height: 752px;
  display: flex;
  position: relative;
  max-width: 100%;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  margin: 20px;
  margin-left: 40px;
}



.front-end {
  width: 100vw;
  height: 100vh;
  position: absolute;
  object-fit: cover;
  /* border-color: rgba(0, 0, 0, 0.30000001192092896);
    border-style: solid;
    border-width: 1px; */
  background-color: rgba(255, 255, 255, 0);
  background-size: cover;
}

.groupViewePage {
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  /* position: absolute; */
  /* max-width: 1337px; */
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}

.Group14291011 {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 752px;
  display: flex;
  position: absolute;
  max-width: 1337px;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  background-color: transparent !important;
}

.image {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 752px;
  position: absolute;
  object-fit: cover;
  border-color: rgba(0, 0, 0, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
  border-radius: 40px;
  background-color: rgba(255, 255, 255, 1);
  background-size: cover;
  cursor: pointer;

}

.imageColum {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 752px;
  position: absolute;
  object-fit: cover;
  border-color: rgba(0, 0, 0, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
  border-radius: 40px;
  background-color: rgba(255, 255, 255, 1);
  background-size: cover;
  display: flex;
  flex-direction: row;
  cursor: pointer;

}

.wrapper {
  width: 100%;
  height: 25%;
  /* overflow-x: scroll;
    overflow-y: hidden; */
  margin: 0 auto;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(6, auto);
}

.wrapper-classic-min {
  width: 100%;
  height: 25%;
  /* overflow-x: scroll;
    overflow-y: hidden; */
  margin: 0 auto;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(6, auto);
}

@media (max-width: 600px) {
  .wrapper-classic {
    /* align-items: flex-start !important; */
    /* justify-content: center !important; */
    width: fit-content !important;
    width: 90%;
    margin: 0 auto;
    height: 17% !important;
    padding-bottom: 40px;
    display: flex;


  }
}

.wrapper-classic-lateral {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  /* overflow-y: auto; */
  /* overflow-x: auto;
    overflow-y: scroll; */
  height: 100%;
  width: 100%;
  background-color: rgb(252, 252, 252);
  /* overflow-y: unset; */
  /* border-radius: 0 0 40px 40px; */
  overflow: scroll;
  padding: 100px;
}

.wrapper-classic-lateral::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.wrapper-classic-parent {
  width: 100%;
  /* overflow: scroll; */
}

.wrapper-classic-parent::-webkit-scrollbar {
  display: none;
}

.wrapper-classic {
  /* display: block; */
  /* background-color: rgb(252, 252, 252); */
  /* overflow: scroll;
  margin: auto;
  padding: 5px 0; */

  width: 90%;
  margin: 0 auto;
  height: 17% !important;
  padding-bottom: 40px;
  display: flex;
}

.wrapper-classic::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.row-wrapp {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  /* overflow-x: auto;
    overflow-y: hidden; */
  height: 15%;
  width: 100%;
  background-color: rgb(252, 252, 252, 0);
}

.row-wrapp-lateral {
  align-items: center;
  justify-content: start;
  gap: 10px;
  padding-top: 40px;
  background-color: rgb(252, 252, 252);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  /* overflow-x: auto;
    overflow-y: scroll; */
  height: 100%;
  width: 100%;
  padding-right: 50px;
  padding-left: 50px;

  /* overflow-y: auto; */
  overflow: scroll;
}

.row-wrapp-lateral::-webkit-scrollbar {
  display: none;
}

.item {
  cursor: pointer;

  background-color: #fff;
  border-radius: 20px;
  border: 1px solid transparent;
  background-position: center;
  width: 180px;
  height: 120px;
  background-size: contain;
  background-repeat: no-repeat;

  border: 0;
  /* flex-basis: 33.333%; */
  /* margin-top: 5px; */
  float: left;

  margin-left: 10px;
  margin-right: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* bottom: 10; */
}

.itemSelected {
  cursor: pointer;

  background-color: #fff;
  border-radius: 20px;
  border: 1px solid transparent;
  background-position: center;
  width: 180px;
  height: 120px;
  background-size: contain;
  background-repeat: no-repeat;
  float: left;

  border: 0;
  /* flex-basis: 33.333%; */
  /* margin-top: 5px; */

  margin-left: 10px;
  margin-right: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* bottom: 10; */
}

.itemClassic {
  width: 180px;
  /* height: 120px; */
  border: 0;
  /* flex-basis: 33.333%; */
  margin-left: 10px;
  margin-right: 10px;
  /* bottom: 10; */
  text-align: center;
  float: left;
  cursor: pointer;

}



.itemClassic .image_plane {
  width: 90px;
  text-align: center;
  /* height: 120px; */
}

.itemClassicSelected {
  width: 180px;
  /* height: 120px; */
  border: 0;
  /* flex-basis: 33.333%; */
  margin-left: 10px;
  margin-right: 10px;
  /* bottom: 10; */
  text-align: center;
  float: left;
  cursor: pointer;
  /* border-bottom: 2px solid black; */
}

.itemClassicSelected .image_plane {
  width: 120px;
  /* text-align: center; */
  /* height: 120px; */
}

.itemDot .image {
  width: 80px;
}

.itemDot {
  background-color: transparent;
  border-radius: 100%;
  border: 1px solid transparent;
  background-position: center;
  width: 25px;
  height: 25px;
  background-size: cover;
  background-repeat: no-repeat;
  border: 0;
  /* flex-basis: 33.333%; */
  /* margin-top: 8px; */
  margin-left: 10px;
  margin-right: 10px;
  float: left;
  /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); */
  bottom: 0;
  cursor: pointer;

}

.itemDotSelected {
  background-color: white;
  border-radius: 100%;
  border: 1px solid transparent;
  background-position: center;
  float: left;
  width: 30px;
  height: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  border: 0;
  /* flex-basis: 33.333%; */
  /* margin-top: 5px; */
  margin-left: 10px;
  margin-right: 10px;
  /* box-shadow: 0 8px 16px 0 rgba(0,0,0,0.4); */
  bottom: 5;
  cursor: pointer;

}

.selected-indicator {
  width: 30px;
  height: 2px;
  background-color: black !important;
  margin: 0 auto;
  animation: indicatorAnimation 0.6s ease-out;


  /* margin-top: 5px; */
}

/* Definisci barra */
@keyframes indicatorAnimation {
  0% {
    transform: scaleX(0);
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 0;
  }

  50% {
    transform: scaleX(1.2);
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 1;
  }

  100% {
    transform: scaleX(1);
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 1;
  }
}

@media screen and (max-width: 900px) {
  .row-wrapp-lateral {
    background-color: rgb(252, 252, 252, 0);
  }

  .itemClassic {
    width: 110px;
    /* height: 120px; */
    border: 0;
    flex-basis: 33.333%;
    margin-top: 5px;
    margin-left: 5px;
    bottom: 10;
    text-align: center;
  }

  .itemClassic .image_plane {
    width: 90px;
    text-align: center;
    /* height: 120px; */
  }

  .itemClassicSelected {
    width: 110px;
    /* height: 120px; */
    border: 0;
    flex-basis: 33.333%;
    margin-top: 5px;
    margin-left: 5px;
    bottom: 10;
    text-align: center;
    /* border-bottom: 2px solid black; */
  }

  .itemClassicSelected .image_plane {
    width: 110px;
    /* text-align: center; */
    /* height: 120px; */
  }
}


.image_plane {
  width: 110px !important;
}