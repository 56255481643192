.modal {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    background-color: white;
    width: 80%;
    height: 50%;
    z-index: 1000;
    position: fixed;
    top: 25%;
    left: 10%;
    display: block !important;
    overflow-y: auto;
  }
  
  
  .modalTwo {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    background-color: white;
    text-align: center;
    width: 25%;
    height: 100%;
    z-index: 999;
    position: fixed;
    top: 0;
    left: 75%;
    display: block !important;
    overflow-y: auto;
  }
  
  .modal-rename {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    background-color: white;
    text-align: center;
    width: 500px;
    height: 150px;
    z-index: 999;
    position: fixed;
    top: 50%;
    left: calc(50% - 250px);
    display: block !important;
    overflow-y: auto;
    padding: 8px;
  }
  
  .modal-loading{
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    background-color: white;
    text-align: center;
    width: 200px;
    height: 200px;
    z-index: 999;
    position: fixed;
    top: 50%;
    left: calc(50% - 250px);
    display: block !important;
    overflow-y: auto;
    padding: 8px;
  }
  
  .modal-dialog {
    overflow-y: initial !important;
  }
  
  .modal-body {
    padding: 0px;
  }
  