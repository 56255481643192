/* Menu.css */
.menu {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra orizzontalmente */
    justify-content: center; /* Centra verticalmente */
    height: 100%; /* Usa l'intera altezza disponibile del contenitore padre */
    width: 25%;
    background-color: white;
  }
  
  .menu-item {
    width: 140px;
    height: 25px;
    border-radius: 10px;
    cursor: pointer;
    margin: 15px 0; /* Aggiunge 2px di spazio sopra e sotto ogni pallino */
}
  
.item-1 {
    background: linear-gradient(#7C5144, #363636);
}
.item-2 {
    background: linear-gradient(#000000, #63737A);
}
.item-3 {
    background: linear-gradient(#000000 , #472800 );
}
.item-4 {
    background: linear-gradient(#000000 , #798067 );
}
.item-5 {
    background: linear-gradient(#B0B2AF , #444444 );
}
.item-6 {
    background: linear-gradient(#92850e , #504B4B );
}

.selected {
    transform: scale(1.1); /* Questo aumenterà la dimensione del pulsante del 10% */
    transition: transform 0.3s ease; /* Questo aggiungerà un'animazione morbida all'effetto di scaling */
  }

  
  /* Aggiungi qui altri stili per altri pallini */
  
  @media (max-width: 768px) { /* Ad esempio, per dispositivi con larghezza <= 768px */
    @media (max-width: 768px) {
        .menu {
            width: 100%;
            height: 25%;
            flex-direction: row;
        }

        .menu-item {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            cursor: pointer;
            margin: 10px 0; /* Aggiunge 2px di spazio sopra e sotto ogni pallino */
        }
    
        .menu-item {
            margin: 0 10px; /* Aggiunge 2px di spazio a sinistra e a destra per la versione mobile */
        }

          /* Stile per il pallino con gradiente */
  .item-1 {
    background: linear-gradient(to top right, #7C5144 40%, #444444 60%);
}
.item-2 {
    background: linear-gradient(to top right, #000000 40%, #63737A 60%);
}
.item-3 {
    background: linear-gradient(to top right, #000000 40%, #472800 60%);
}
.item-4 {
    background: linear-gradient(to top right, #000000 40%, #5C614F 60%);
}
.item-5 {
    background: linear-gradient(to top right, #B0B2AF 40%, #444444 60%);
}
.item-6 {
    background: linear-gradient(to top right, #F5DD0E 40%, #504B4B 60%);
}
    }
  }
  