.action-icons {
  color: $chart_header_background;
  height: 25px;
  width: 25px;
  margin: 5px;
  cursor: pointer;
}

.model-container {
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center top;
  background-attachment: fixed;
}

.published-modal-status {
  background-color: $green;
}

.ready-modal-status {
  background-color: $yellow;
}

.incomplete-modal-status {
  background-color: $red;
}

.container-heading-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.back-icon {
  width: 20px;
  height: 20px;
}

.page-heading-text {
  font-weight: 600;
  font-size: 20px;
  margin-top: 14px;
}

.search-container {
  // margin-top: -50px;
  margin-bottom: 50px;
}

.cardBrandSelector {
  margin-top: 50px;
  margin-left: 20px;
}

.modal-iframe {
  .modal-iframe-heading {
    font-weight: 600;
    font-size: 17px;
    text-align: left;
    margin-top: 40px;
    margin-left: 20px;
  }

  .iframe-container-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .iframe-container {
    height: 207px;
    overflow-y: auto;
    background-color: $text-area-background;
    border-radius: 4px;
    margin-top: 10px;
    padding: 10px;
    width: 90%;
    border-radius: 25px;

    textarea {
      width: 100%;
      resize: none;
      border: 0;
      background-color: $text-area-background;
      text-align: center;
    }
  }

  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    button {
      width: 30%;
      background-color: $auth_background !important;
      border-color: $auth_background !important;

      .copy-icon {
        margin-right: 5px;
      }
    }
  }
}

@media (max-width: 767px) {
  .container-heading-wrapper {
    padding: 10px;
  }
  .modal-iframe {
    .button-container {
      button {
        width: 60%;
      }
    }
  }
}
