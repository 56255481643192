/* .centered {
  margin: 3rem auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-light {
  color: white;
}

.poster-img-preview {
  width: 200px;
  height: 200px;
  border-radius: 13.5px;
}

model-viewer {

 position: absolute;
   width: 100vh;
  height: 80vh; 
} */

/* #project {
  min-width: 100% !important;
  min-height: 80% !important;
  position: relative;
  text-align: center;
}

#projectTwo {
  min-width: 80% !important;
  min-height: 100% !important;
  position: relative;
  text-align: center;
}

@media only screen and (max-width: 980px) {
  #projectTwo {
    min-width: 70% !important;
    min-height: 100% !important;
    position: relative;
  }
}
.fixedPowered {
  position: fixed;
  z-index: 999;
  margin-left: calc(50% - 60px);
  margin-top: 10px;
} */

/* #projectTwo {
  min-width: 80% !important;
  min-height: 100%  !important;
  position:relative;
} */
/* from doc hotspot*/

/* .dot {
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  background: #fff;
  --min-hotspot-opacity: 0;
}

.dim {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  color: rgba(0, 0, 0, 0.8);
  display: block;
  font-family: Futura, Helvetica Neue, sans-serif;
  font-size: 18px;
  font-weight: 700;
  max-width: 128px;
  overflow-wrap: break-word;
  padding: 0.5em 1em;
  position: absolute;
  width: max-content;
  height: max-content;
  transform: translate3d(-50%, -50%, 0);
  --min-hotspot-opacity: 0;
}

.show {
  --min-hotspot-opacity: 1;
}

.hide {
  display: none;
}
/* This keeps child nodes hidden while the element loads */
/* :not(:defined) > * {
  display: none;
}

.color {
  width: 50px;
  height: 50px;
  border-radius: 13px;
}

.picker1 {
  display: none;
}

.popup {
  z-index: 999;
}

.custom-layout {
  position: absolute;
  top: 10px;
  right: 101%;
  z-index: 999;
  width: 250px;
  height: 300px;
  padding: 16px;
  border-radius: 12px;
  background: #2c323f;
  box-shadow: 0 2px 2px #999;
}
.custom-layout .react-colorful {
  z-index: 999;
  margin: 0 auto;
}

.custom-layout .react-colorful__saturation {
  margin: 15px 0;
  border-radius: 5px;
  border-bottom: none;
  z-index: 999;
}

.custom-layout .react-colorful__hue {
  order: -1;
}

.custom-layout .react-colorful__hue,
.custom-layout .react-colorful__alpha {
  z-index: 999;

  height: 14px;
  border-radius: 5px;
}

.custom-layout .react-colorful__hue-pointer,
.custom-layout .react-colorful__alpha-pointer {
  z-index: 999;

  width: 20px;
  height: 20px;
}

.picker__swatches {
  display: flex;
  padding: 12px;
  flex-wrap: wrap;
}

.picker__swatch {
  width: 24px;
  height: 24px;
  margin: 4px;
  border: none;
  padding: 0;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
}

.isBorder {
  width: 50px;
  height: 50px;
  border-radius: 8px;
  background-color: #2c323f;
}

.se-toolbar {
  display: block;
  position: relative;
  height: auto;
  width: 100%;
  overflow: visible;
  padding: 0;
  margin: 0;
  background-color: #2c323f;
  outline: 1px solid #dadada;
  z-index: 5;
}  */

body{
  background-color: transparent !important;
}

:root{
  --primary-color: #241F45;
  --secondary-color: #42b1ac;
}

.menu-container-horizontal {
  position: relative; /* Per posizionare le frecce all'interno del container */
  max-width: 100%;
  width: 100%;
  /* overflow: hidden; Nasconde le frecce che escono dal container */
  overflow: auto;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-top: 15px;
}

.scroll-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 100;
  display: none;
}

.scroll-arrow.left {
  left: 0;
}

.scroll-arrow.right {
  right: 0;
}

/* Stile generale dell'accordion */
.accordion {
  background-color: #fff;
  /* border: 1px solid #ccc; */
  margin-bottom: 10px;

}

/* Header dell'accordion */
.accordion-header {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  background-color: #ffffff;
  /* border-bottom: 1px solid #ccc; */
}

.accordion-brand {
  flex-grow: 1;
  font-weight: bold;
}

.accordion-dates {
  margin-left: 10px;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.vertical-td{
  padding: 20px;
  vertical-align: middle !important;
}

.service-icon{
  width: 25%;
  height: 50px;
}
.service-list{
  justify-content: center;
  display: flex;
}
.accordion-icon {
  text-align: center;

  font-size: 18px;
  margin-left: 10px;
  justify-content: center;
}

/* Dettagli dell'accordion */
.accordion-details {
  padding: 10px;
}

.accordion-details div {
  margin-bottom: 5px;
}

.accordion-details ul {
  list-style-type: disc;
  margin-left: 20px;
}


.license_status {
  color: #fff;
  font-weight: bold;
  border-radius: 20px;
  text-align: center;
}

.expired_service {
  background-color: #ff2464;
}

.active_service {
  background-color: #41b1ac;
}

.pie_chart_container{
  width: 45%;
  padding: 10px;
}

.validation-body {
  margin-left: 90px !important;
  padding: 0px !important;
  margin-top: 0px !important;
}

.page-wrapper .page-body-wrapper .page-body {
  margin-top: 10px !important;
}

@media (max-width: 768px) {
 .pie_chart_container{
      width: 100%;
  }
}

/* .rdrDayNumber, span{
  margin-left: 0px;
} */

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 3rem;
}