.order-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    padding: 20px;
    background-color: #f4f4f4;
}

.centered {
    justify-content: center;
}

.dropzone:hover {
    background-color: #002244;
}

.dropzone {
    border: 2px dashed #007bff;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    margin-bottom: 70px;
    /* Aumenta il margine inferiore per separare la barra di ricerca */
    color: #ffffff;
    background-color: var(--primary-color);
    ;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
}

.search-container-orderPage {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    /* Margine inferiore per separare dalla tabella o altri contenuti sottostanti */
}

.search-container-orderPage input {
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    margin-right: 10px;
    width: 200px;
    /* Width for input field */
}

.search-container-orderPage button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: var(--primary-color);
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.search-container-orderPage button:hover {
    background-color: var(theme-font-color);
    transform: scale(1.05);
}

.filter-container {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.filter-label {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    color: #333;
}

.filter-select {
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.filter-select:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
}

.table-container {
    width: 100%;
    max-width: 1200px;
    overflow-x: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background-color: #ffffff;
}

table {
    width: 100%;
    border-collapse: collapse;
    color: #333333;
}

th,
td {
    border: 1px solid #dddddd;
    padding: 16px;
    text-align: left;
    transition: background-color 0.3s ease;
}

th {
    background-color: #003366;
    color: #ffffff;
    font-weight: bold;
}

tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

tbody tr:nth-child(odd) {
    background-color: #ffffff;
}

tbody tr:hover {
    background-color: #e0e0e0;
}

.status-summary {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.status-box {
    width: 120px;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin: 0 10px;
    margin-bottom: 10px;
    text-align: center;
}

.status-box.active {
    border: 2px solid #000;
    transform: scale(1.05);
}

.status-box:hover {
    background-color: #333;
}

.search-container-orderPage {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.search-container-orderPage .search-input-group {
    display: flex;
    align-items: center;
    /* Allinea verticalmente l'input e il pulsante */
    margin-top: 10px;
}

.search-container-orderPage input,
.search-container-orderPage button {
    margin: 0;
    /* Rimuovi il margine verticale tra input e pulsante */
}

.search-container-orderPage button {
    margin-left: 10px;
    /* Aggiungi un po' di spazio tra l'input e il pulsante */
}

.dropzone {
    border: 2px dashed #ddd;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
}

.dropzone.hidden {
    display: none;
}

.search-input-group {
    display: flex;
    align-items: center;
    gap: 10px;
}

.search-input-group input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.search-input-group button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: white;
    color: black;
    cursor: pointer;
}

.reload-icon {
    position: absolute;
    top: 20px;
    left: 145px;
    font-size: 24px;
    cursor: pointer;
    color: var(--primary-color);
    z-index: 1000;
}