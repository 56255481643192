.edit_ar_view_label {
  color: $white;
  font-weight: 600;
  font-size: 15px;
}

.edit_ar_enable_background_image_label {
  margin-top: 8px;
  color: $white;
  font-weight: 600;
  font-size: 15px;
  width: 150px;
}

.edit-ar-solid-button {
  height: 50px !important;
  width: 100% !important;
  background-color: $white;
  margin: 10px 0px;
}

.ar-view-wrapper {
  margin-top: 5%;
}

.edit-ar-outlined-button {
  height: 50px !important;
  width: 100% !important;
  border: 1px solid $white;
  margin: 10px 0px;
  color: $white;
  margin-bottom: 20px;
}

// .menu-container-horizontal {
//   max-width: 100%;
//   overflow: scroll;
//   margin-bottom: 5px;
//   margin-top: 5px;
// }

// .menu-container-horizontal::-webkit-scrollbar {
//   display: none;
// }



.menu-container-vertical {
  max-width: 100%;
  overflow: scroll;
}

.menu-container-vertical::-webkit-scrollbar {
  display: none;
}

.edit-item-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ar-icon-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
}

.color-picker-container {
  position: relative;
}

.color-picker-container-copy {
  position: relative;
  background-color: white;
  border-radius: 5px;
  width: 70px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  row-gap: 4px;

  // bottom: 10px;
}

.ar-icon {
  position: relative;
  background-color: white;
  border-radius: 5px;
  width: 10vh;
  height: 5vh;
  position: relative;
  bottom: 20px;
}

.dot {
  height: 20px;
  width: 20px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  right: 5px;
  top: 7px;
}

.color-picker-input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.color-picker-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #000;
}

.color-picker-icon {
  color: #000;
  font-size: 18px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 22px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 3px solid $chart_header_background;
  border-radius: 17px;
  transition: 0.4s;
}

.slider:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: $chart_header_background;
  top: 0.5px;
  transition: 0.4s;
}

.switch input:checked+.slider:before {
  transform: translateX(12px);
}

// Define variables for the component
$input-range-bg-color: #f8f9fa;
$input-range-thumb-color: #007bff;
$input-range-thumb-size: 16px;
$input-range-height: 6px;
$input-range-container-height: 24px;

// Define the input range component
.input-range-container {
  position: relative;
  width: 100%;
  height: $input-range-container-height;
}

.input-range {
  position: absolute;
  width: 100%;
  height: $input-range-height;
  background-color: $input-range-bg-color;
  border-radius: 10px;
  z-index: 1;
}

.edit_ar_view_sub_label {
  color: $white;
}

.select-wrapper {
  position: relative;
  margin-bottom: 20px;

  .select {
    width: 100%;
    height: 40px;
    padding: 8px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
  }
}

.left-side-bar-container {
  margin-top: -20px;
  border-radius: 50px;
  background-color: #ebeced;
  height: 540px;
  width: 540px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  margin: 10px 30px;

  @media (max-width: 1000px) {
    margin-top: -100px; // Set marginTop to -20px on mobile view
  }
}

.edit-vr-top-container {
  display: flex;
  justify-content: space-between;

  // margin: -50px;
  // margin-left: -80px;
  //  margin-top: -100px;
  // overflow: scroll;
  @media (max-width: 1000px) {
    margin-top: -15px;
    margin-left: -20px;
    flex-direction: column;
    gap: 2rem;
  }
}

.page-title {
  padding-left: 20px;
  cursor: pointer;
}

.edit-right-side-bar {
  height: 94vh;
  width: 350px;
  background-color: var(--primary-color);
  padding: 40px;
  overflow-x: hidden;
  overflow-y: scroll;

  @media (max-width: 1000px) {
    width: 100%;
    height: 100%;
  }
}

.edit-right-side-bar::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}