.flexGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.row-element {
  display: flex;
  flex-direction: row;
  padding: 5px;
  margin-top: 20px;
  margin-left: 10px;
}

.model {
  width: 250px;
  height: 225px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.model-img {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  border-radius: 20px;
  /* background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 100%,
    rgba(0, 0, 0, 0) 100%,
    rgba(0, 0, 0, 0) 100%
  ); */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.model-image {
  width: 100%;
  height: 100%;
  box-shadow: 0px 10px 26px -4px rgba(0, 0, 0, 0.4000000059604645);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  object-fit: cover;
  border-color: transparent;
  border-radius: 20px;
  background-color: rgba(216, 206, 206, 1);
}

.Group {
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  bottom: 5px;
}

.model-state {
  padding: 10px 20px;
  margin-top: 20px;
  margin-left: 0px;
  color: white;
  border-radius: 22px;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 10px;
  font-style: normal;
  text-align: left;
  font-family: Nunito;
  font-weight: 700;
  font-size: 13px;
  opacity: 0.9;
}

.label {
  padding: 10px 20px;
  margin-top: 182px;
  margin-left: 0px;
  color: #000;
  border-top-right-radius: 22px;
  border-bottom-left-radius: 22px;
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  font-style: normal;
  text-align: left;
  font-family: Nunito;
  font-weight: 700;
  font-size: 18px;
  opacity: 0.9;
}

.svg {
  top: 8.375110626220703px;
  left: 8.571223258972168px;
  width: 14;
  height: 14;
  position: absolute;
}
.svg2 {
  width: 24px;
  height: 24px;
  margin: 5px;
  /* filter: invert(77%) sepia(77%) saturate(1219%) hue-rotate(128deg)
    brightness(88%) contrast(91%); */
}