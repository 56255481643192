.buttonArShades{
    width: 100%;
    height: 50px;
    border-radius: 5px;
}

.buttonArShades3D{
    width: 250px;
    height: 50px;
    border-radius: 5px;
}

.content{
    background-color: white;
   
    }

    
.contentQr{
    background: rgb(42,60,90);
    background: linear-gradient(173deg, rgba(42,60,90,1) 0%, rgba(62,155,156,1) 97%);
        height: 100vh;
}


.containerButton{
    width: 100%;
    height: 50px;
    border-radius: 5px;
    border: 1px solid;
    display: flex;
    justify-content: center;
    border-color: var(--secondary-color);
    background-color: white;
    margin: 0px;
    margin-top: 0px;
}

.containerButton3D{
    width: 250px;
    height: 50px;
    margin: 0 auto;
    border-radius: 5px;
    border: 1px solid;
    display: flex;
    justify-content: center;
    border-color: var(--secondary-color);
    background-color: white;
}

.containerButtonQr{
    width: 80%;
    height: 50px;
    border-radius: 5px;
    border: 1px solid;
    display: flex;
    justify-content: center;
    border-color: var(--secondary-color);
    background-color: white;
    margin: 0 auto;
    margin-top: 20px;
}

.containerButtonQr:hover{
    background-color: var(--primary-color);
 }
 
 .containerButtonQr:hover .itemText{
     display:none;
  }
 
  .containerButtonQr:hover .itemTextHovered{
     display:contents;
 }
 
 .containerButtonQr:hover .itemTextHovered p{
     display:contents;
 }


.containerButton:hover{
   background-color: var(--primary-color);
}

.containerButton:hover .itemText{
    display:none;
 }

 .containerButton:hover .itemTextHovered{
    display:contents;
}

.containerButton:hover .itemTextHovered p{
    display:contents;
}

 .itemText p{
    height: 100%;
    display:absolute;
    text-align: center;
    line-height: 50px;
    font-family: "Roboto", Sans-serif;
    font-size: 16px;
    font-weight: 400;}

.itemTextHovered p{
    height: 100%;
    display:none;
    line-height: 50px;
    font-family: "Roboto", Sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: white;
}
.iconArshades{
    width: 50px;
    height:50px;
    padding-right: 20px;
}

.itemIcon{
    width: 50px;
    height: 100%;
    display:absolute;
}



