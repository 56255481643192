.charts-container {
  padding: 20px;

  .filter-form-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
  }

  .filter-form-button {
    height: 47px;
    border-radius: 5px;
  }

  .apply-button {
    background-color: $auth_background !important;
    border-color: $auth_background !important;
  }

  .clear-button {
    background-color: $white !important;
    border-color: $auth_background !important;
    color: black;
  }

  .apply-button:hover {
    background-color: $auth_background_hover !important;
    border-color: $auth_background_hover !important;
  }

  .apply-button:focus {
    background-color: $auth_background_hover !important;
    border-color: $auth_background_hover !important;
  }

  .apply-button:disabled {
    background-color: $auth_background_disabled !important;
    border-color: $auth_background_disabled !important;
  }

  .clear-button:hover {
    background-color: $white !important;
    border-color: $auth_background !important;
    color: $black;
  }

  .filter-datepicker {
    padding: 10px;
    background: $white !important;
    border: 1px solid $white !important;
    border-radius: 5px;
    box-shadow: 0px 2px 4px $card_shadow; /* Add box shadow */
  }

  .filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .date-range-filter {
      display: flex;
      align-items: center;

      label {
        margin-right: 10px;
        color: $black;
      }
    }
  }

  .bar-chart-heading-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 5px;
    margin-left: 20px;
    margin-top: 20px;
  }

  .bar-chart-heading-title {
    font-size: 35px;
  }

  .bar-chart-heading-subTitle {
    font-size: 17px;
    color: $sub-title;
  }

  .bar-chart-data{
    padding-left: 20px;
  }

  .charts {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;

    .bar-chart-heading-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      gap: 5px;
      margin-left: 20px;
      margin-top: 20px;
    }

    .bar-chart-heading-title {
      font-size: 35px;
    }

    .bar-chart-heading-subTitle {
      font-size: 17px;
      color: $sub-title;
    }

    .bar-chart,
    .line-chart {
      border: 1px solid $black;
      padding: 20px;
      background-color: $white;

      h2 {
        margin-top: 0;
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: bold;
      }

      canvas {
        max-width: 100%;
        height: auto;
      }
    }
  }
}
