/**=====================
     31. NAV CSS Start
==========================**/
.nav-list {
  padding: 30px;
  .nav-list-disc {
    text-align: left;
    color: $dark-gray;
    li {
      padding: 9px 0;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
      a {
        color: $theme-body-font-color;
        .fa {
          width: 14px;
          font-size: 16px;
        }
      }
      &:hover {
        color: $primary-color;
        a {
          color: $primary-color;
        }
      }
    }
  }
}
.navs-icon {
  padding: 30px;
  div {
    &:hover {
      background-color: $transparent-color;
    }
  }
  svg {
    width: 21px;
    height: 21px;
    margin-right: 10px;
  }
  .fa {
    font-size: 16px;
    margin-right: 10px;
  }
  li {
    padding: 9px 0;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
    .text-muted {
      color: $theme-body-font-color !important;
    }
    a {
      display: flex;
      align-items: center;
    }
    ul {
      padding-top: 9px;
      li {
        line-height: 1;
        &:first-child {
          padding-top: 9px;
        }
        &:hover {
          color: $primary-color;
          a {
            color: $primary-color;
          }
        }
      }
    }
    a {
      color: $theme-body-font-color;
      &:hover {
        color: $primary-color;
      }
      &.active {
        color: $primary-color;
      }
      span {
        font-family: $font-work-sans, $font-serif;
      }
    }
    button {
      &:hover {
        a {
          color: #fff;
        }
      }
    }
  }
  .main-section {
    padding-top: 30px;
  }
  .separator {
    border-bottom: 1px solid #dee2e6;
    margin: 30px 0;
    padding: 0;
  }
  .btn-link {
    text-decoration: none;
    line-height: 1;
    padding: 0;
    padding-bottom: 6px;
    &:hover {
      text-decoration: none;
    }
  }
}
#accordionoc {
  #collapseicon,
  #collapseicon1 {
    padding-top: 0px;
  }
  li {
    button {
      display: flex;
      align-items: center;
    }
  }
}
.navs-icon.default-according.style-1 {
  li {
    button {
      position: relative;
      &:hover {
        color: $primary-color !important;
      }
    }
    button[aria-expanded="true"] {
      &:before {
        right: 2px;
        top: 6px;
      }
    }
    button[aria-expanded="false"]:before {
      right: 2px;
      top: 6px;
    }
  }
}
.navs-dropdown {
  button {
    margin-top: 30px;
  }
  .onhover-show-div {
    width: 250px;
    border-radius: 4px;
    box-shadow: 0 0 2px 2px $light-color;
    button {
      margin-top: 0px;
    }
    .navs-icon {
      li {
        text-align: left;
        a {
          &:hover,
          &.active {
            svg {
              path,
              line,
              polyline {
                color: $primary-color !important;
              }
            }
          }
        }
      }
    }
  }
}
.onhover-dropdown.navs-dropdown:hover .onhover-show-div:before {
  right: 77px;
  left: unset;
}
.onhover-dropdown.navs-dropdown:hover .onhover-show-div:after {
  right: 77px;
  left: unset;
}
.icon-lists {
  font-family: $font-nunito, $font-serif;
  .collapse {
    height: unset !important;
    &.show {
      height: unset !important;
    }
  }
}
.pl-navs-inline {
  padding-left: 30px !important;
}
.inline-nav {
  li {
    line-height: 1.3;
  }
}
ul.icon-lists {
  border-radius: 30px;
}
.nav-list {
  &.border {
    border-radius: 30px;
  }
}

.navbar {
  background-color: $side_bar_background;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $white;
  border-bottom: 1px solid $border-bottom;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
}

.nav-menu {
  background-color: $side_bar_background;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: calc(-250px);
  transition: 850ms;
  z-index: 1;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: $white;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text {
  a {
    &:hover {
      background-color: $white;
      .nav-icon {
        color: $black;
      }
      span {
        color: $black;
      }
      
    }
  }
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: $side_bar_background;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

span {
  margin-left: 16px;
  color: $white;
}

.logo {
  width: 50px;
  height: 50px;
}

.logo-text {
  color: $white;
  font-size: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 30px;
}

.logo-text-container {
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.nav-icon {
  color: $white;
}

.nav-menu-icon {
  color: $black;
}

.nav-content {
  display: flex;
  align-items: center;
}

.nav-heading {
  font-size: 25px;
  margin-bottom: 8px;
}

.select-input-container {
  background-color: $input_background;
  padding-right: 15px;
  margin-right: 20px;
  height: 50px;
  width: 200px;
}

.top-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
}

.logo-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  height: 100%;
  padding-left: 20px;
  padding-top: 30px;
}

.divider {
  height: 1px;
  background-color: $white;
  margin: 10px 20px;
}

.back-arrow {
  margin: 10px 25px;
}

@media (max-width: 1000px) {
  .back-button {
    display: block;
  }
  .nav-heading {
    display: none;
  }
}

/* This will show the div on screens that are wider than 768px (i.e. desktop devices) */
@media (min-width: 1000px) {
  .back-button {
    display: none;
  }
  .nav-heading {
    margin-left: 200px;
  }
}

/**=====================
     31. NAV CSS Ends
==========================**/
