.canvas29985 {
  width: 100vw;
  height: calc(100vh - (100vw - 100%) / 2);
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  background-color: #ffffff00;
}



.Group14291011 {
  top: 0;
  left: 0;
  width: 100%;
  /* height: calc(100vh - (100vw - 100%) / 2); */
  height: 100svh;
  display: flex;
  position: absolute;
  overflow-y: hidden;
  /* max-width: 1337px; */
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  background-color: #ffffff00;

}

.image {
  top: 0px;
  left: 0px;
  width: 100vw;
  height: calc(100svh);
  position: absolute;
  object-fit: cover;
  border-color: rgba(0, 0, 0, 0.30000001192092896);
  /* border-style: solid; */
  /* border-width: 1px;
    border-radius: 40px; */
  background-color: rgba(255, 255, 255, 0);
  background-size: cover;
}



.imageColum {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  /* border-color: rgba(0, 0, 0, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
  border-radius: 40px; */
  background-color: rgba(255, 255, 255, 0);
  background-size: cover;
  display: flex;
  flex-direction: row;
}
