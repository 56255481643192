/**=====================
     43. Search CSS Start
==========================**/
.search-page {
  .search-form {
    input {
      background-position: 99%;
      &:focus {
        outline: none;
      }
    }
  }
  .nav-link {
    font-weight: 500;
    text-transform: uppercase;
  }
  .border-tab-primary {
    &.nav-tabs {
      .nav-item {
        .nav-link {
          font-size: 14px;
        }
      }
      .dropdown {
        .btn-primary {
          background-color: $transparent-color !important;
          border-color: $transparent-color !important;
          color: $dark-color;
        }
      }
    }
  }

  .info-block {
    + .info-block {
      padding-top: 30px;
      border-top: 1px solid $light-color;
      margin-top: 30px;
    }
  }
  .border-tab-primary.nav-tabs {
    align-items: left;
    justify-content: end;
    .nav-item {
      width: auto;
    }
  }
  .search-links {
    h6 {
      margin-bottom: 0;
    }
  }
  p {
    text-transform: lowercase;
    margin-bottom: 0;
    color: $dark-gray;
  }
  ul.search-info {
    li {
      display: inline-block;
      font-size: 12px;
      line-height: 1;
      color: #586082;
      + li {
        border-left: 1px solid lighten($dark-color, 50%);
        padding-left: 8px;
        margin-left: 8px;
      }
      i {
        color: $warning-color;
      }
    }
  }
  #video-links {
    .embed-responsive + .embed-responsive {
      margin-top: 30px;
    }
  }
}

.search-form {
  display: flex;
  align-items: center;
}

.search-input-container {
  flex: 1;
  position: relative;
}

.search-input {
  width: 485px; 
  padding: 20px !important;
  border: none !important;
  outline: none !important;
  font-size: 16px !important;
  color: $black;
  background-color: $white !important;
  box-shadow: 0px 4px 8px rgba(228, 219, 219, 0.25) !important;
  border-radius: 5px !important;
  &::placeholder {
    color: $light-text;
  }
}

.search-btn {
  position: absolute;
  top: 50%;
  left: 430px;
  transform: translateY(-50%);
  color: #fff;
  border: none;
  outline: none;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: transparent; 
  .search-icon {
    font-size: 24px;
    margin-right: 8px;
    color: black;
    box-shadow: none;
  }
}

.error-msg {
  margin: 0;
  color: $error;
  font-size: 14px;
  margin-top: 4px;
}

@media (max-width: 767px) {
  .search-form {
    padding: 10px; 
  }
  .search-input {
    width: 100%;
  }
  .search-btn {
    left: 80%;
  }
}
/**=====================
    43. Search CSS Ends
==========================**/
