.analytics-tab {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  background: #fff;
  border:3px solid #41b1ac;
  padding: 1rem;
  margin: 0.25rem;
  min-width: 100px;
  cursor: pointer;
  transition: background 250ms ease-in-out;
  &.active {
    background: #41b1ac;
    border:3px solid #41b1ac;
  }
  .analytics-tab-tooltip {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
    display: block;
    color: $dark-small-font-color;
    z-index: 10;

  }
  .analytics-tab-tooltip:hover .tooltip{
    display: block;

  }
  .tooltip{
    display: none;
  }
}




.active .rdrDateRangePickerWrapper {
  display: flex;
}
.rdrCalendarWrapper,
.rdrMonth {
  flex: 1 1 0 !important;
}
.rdrInputRanges {
  .rdrInputRange {
    &:last-child {
      display: none !important;
    }
  }
}
@media screen and (max-width: 767px) {
  .rdrDateRangePickerWrapper {
    flex-direction: column;
  }
  .rdrCalendarWrapper,
  .rdrMonth {
    flex: 1 1 0 !important;
  }
  .rdrDefinedRangesWrapper {
    width: auto !important;
  }
}
.text-primary {
  color: $primary-color !important;
}
.rdt_Table {
  border: none;
  .rdt_TableHead > div {
    background: $light-background;
    border-radius: 4px;
  }
  & * {
    border: none !important;
  }
}

.rdt_Pagination {
  border-top-color: rgba($color: #000000, $alpha: 0.05) !important;
  
}
.breadcrumb {
  height: 100%;
  padding-top: 0.45rem;
  padding-bottom: 0.45rem;
  margin-bottom: 0;
  margin-right: 0.5rem;
}

.rdrStaticRangeLabel {
  color: black;
}
.close > span {
  color: black;
}

.variant-name span {
  color: black;
}

.rdrInputRange > span {
  color: black;
}
