.login-form-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: $auth_background;
  z-index: 1;
  overflow: hidden;

  .logo {
    width: 55px;
    height: 55px;
    margin-left: -5px;
  }

  .login-card {
    width: 400px;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 0px 10px $card_shadow;
    overflow: hidden;

    .card-header {
      background-color: $white;
      color: $black;
      border-radius: 10px 10px 0 0;
      min-height: 150px;
      max-height: 250px;
      margin-left: -30px;
    }

    .login-card-header {
      height: 150px;
    }

    .card-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px 20px;

      form {
        width: 100%;

        .form-group {
          margin-bottom: 20px;

          label {
            font-weight: bold;
          }

          input {
            border-radius: 5px;
            border: none;
            box-shadow: 0px 0px 5px $card_shadow;
            padding: 10px;
            font-size: 16px;
          }

          .password-input {
            position: relative;

            input {
              padding-right: 40px;
            }

            .password-toggle {
              position: absolute;
              right: 10px;
              top: 10px;
              cursor: pointer;
            }
          }

          .invalid-feedback {
            display: block;
            margin-top: 5px;
            font-size: 14px;
            color: $error;
          }

          .forgot-password {
            text-align: right;
            margin-top: 10px;
          }

          .reset-password {
            display: flex;
            justify-content: space-between;
            padding: 10px 1px;
          }

          .resend-text {
            margin-top: 15px;
          }

          .forgot-password-anchor {
            /* Reset default button styles */
            background-color: transparent;
            border: none;
            cursor: pointer;
            /* Additional styles to match anchor link appearance */

            color: $black;
            padding: 0;
            margin: 0;
            font-size: inherit;
            font-family: inherit;
          }

          .forgot-password-anchor:focus,
          .forgot-password-anchor:hover {
            /* Additional styles for hover/focus state, if desired */
            outline: none; /* Remove focus outline */
            text-decoration: none; /* Remove underline on hover */
          }

          .resend-anchor {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .circle {
    position: absolute;
    width: 4200px;
    height: 4200px;
    left: -1260px;
    top: 550px;
    z-index: -1;
    overflow-y: hidden;
    background-color: $auth_circle_background;
  }

  .recaptcha-container {
    padding: 10px;
  }

  .card-heading {
    font-size: 25px;
  }

  .card-sub-heading {
    font-size: 15px;
  }

  .submit-button {
    background-color: $auth_background !important;
    border-color: $auth_background !important;
    height: 50px !important;
    border-radius: 15px !important;
  }

  .submit-button:hover {
    background-color: $auth_background_hover !important;
    border-color: $auth_background_hover !important;
  }

  .submit-button:disabled {
    background-color: $semi-dark !important;
    border-color: $semi-dark !important;
  }

  .back-to-login {
    background-color: $white !important;
    border-color: $auth_background !important;
    height: 50px !important;
    border-radius: 15px !important;
    color: $auth_background;
  }

  .back-to-login:hover {
    background-color: $auth_background !important;
    border-color: $auth_background !important;
    color: $white;
  }

  .otp-input {
    width: 50px;
    height: 50px;
    border-radius: 15px;
    margin: 0 5px;
    text-align: center;
  }

  .otp-input-container {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .login-form-container {
    .login-card {
      width: 95%;
    }

    .circle {
      display: none;
    }
  }
}
