.container {
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("https://ar3d.spaarkly.it/model3d/background_spaarkly2.png");
  text-align: center;
}

.subContainer {
  position: absolute;
  top: 41vh;
  left: 35vw;
}

.label {
  /* padding-top: 5px; */
  color: white;
  font-size: 25px;
}

.input {
  /* padding: 10px; */
  width: 500px;
  font-size: 16px;
  border-radius: 5px;
  border: 2px solid black;
  margin-top: 20px;
  text-align: center;
}

.button {
  width: 250px;
  text-align: center;
  /* margin: 10px; */
  border-radius: 9px;
  font-family: Georgia;
  color: black;
  font-size: 14px;
  background: white;
  border-color: #000000;
  border: 1px solid;
  padding: 5px 25px 5px 25px;
  text-decoration: none;
  margin-top: 20px;
}

.errorMsg {
  color: red;
  /* margin-top: 70px; */
  font-size: large;
}

@media (max-width: 640px) {
  .subContainer {
    top: 20vh;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .input {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .button {
    width: 80%;
  }
}

@media (max-width: 820px) {
  .subContainer {
    top: 15vh;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .input {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .button {
    width: 80%;
  }
}

