.home_container {
  display: flex;
  row-gap: 50px;
  position: relative;
  padding: 20px;
  bottom: 50px;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 80px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
  }
}

.chart-content-container-height {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: auto;
  @media screen and (max-width: 1024px) {
    height: 460px;
    width: auto;
  }
}

.chart-height {
  height: "400px !important";
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 13px;
  position: relative;
  // bottom: 50px;
  margin: 0 10px;
}

.line_chart {
  height: 100%;
  width: 100%;
  flex: 0.5;
}

.selector-container {
  width: 50%;
}

.pie_chart {
  height: 100%;
  width: 80%;
  flex: 0.5;
  padding: 10px;

  @media (max-width: 768px) {
    height: 50%;
  }
}

.footer-bottom {
  height: "180vh";
}
