.container {
  display: flex;
  margin: 25px;
  /* width: auto; */
}

.subContainer {
  position: absolute;
  left: 33vw;
  top: 0;
}

.row:hover{
  background-color:#cccccc5f;
  cursor: pointer;
}
