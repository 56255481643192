.backdrop {
  position: fixed;
  z-index: 998;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.backdropGrey {
  position: fixed;
  z-index: 998;
  background-color: rgb(0 0 0 / 46%);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
}

.backdropIframe {
  position: fixed;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
}