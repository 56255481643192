.glass-description {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 1.5rem;

  .glass-description-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
      font-size: 14px;
    }
  }
}
.color-title {
  min-width: 50px;
}
.circle,
.square {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  color: white;
  font-weight: 700;
  border: 1px solid rgba($color: #000000, $alpha: 0.1);
  background: rgba($color: $primary-color, $alpha: 0.7);
  overflow: hidden;
  &:not(:last-child) {
    margin-right: 0.5rem;
  }
}
.square {
  border-radius: 4px;
}
.circle-small,
.square-small {
  width: 16px;
  height: 16px;
  overflow: hidden;
}
.half-color-block {
  height: 100%;
  width: 50%;
}
.size {
  background: rgba($color: $warning-color, $alpha: 0.9);
}
.text-24 {
  font-size: 24px;
}
.cursor-pointer {
  cursor: pointer;
}

.dropdown-menu::-webkit-scrollbar {
  display: none;
}

.dropdown-style {
  outline: "0.01em solid black";
  width: "100%";
  max-height: "20rem";
  overflow: "scroll";
}
.dropdown-style::-webkit-scrollbar {
  display: none;
}

.disabled-link {
  cursor: not-allowed;
  opacity: 0.5;
}

.card-tip::before {
  content: " ";
  display: block;
  height: 25px;
  width: 25px;
  background-color: var(--secondary-color);
  position: absolute;
  transform: translate(-50%, 50%) rotate(45deg);
  bottom: 0;
  left: 50%;
}
