.md-animation-custom{
  width: 100%;
  height: 100%;
  position: absolute;
}

.md-animation-custom-three{
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  background-color: #f3f3f3;
}
.viewer3d {
  width: 75%; /* 3/4 della larghezza */
}



@media (max-width: 768px) {
  .md-animation-custom-three {
    flex-direction: column-reverse; /* menu in basso e model-viewer sopra */
  }
  
  .viewer3d {
    height: 75%; /* 3/4 dell'altezza */
    width: 100%;
  }

  
}




.md-container{
  width: 100%;
  height: 100%;
}

.md-animation-button{
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  z-index: 10;
  position: absolute;
  bottom: 40px;
  border: 0px;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid #cd171a;
  text-transform: uppercase;
  left: 50%;               /* Sposta il pulsante di metà larghezza del suo contenitore */
  transform: translateX(-50%); /* Sposta il pulsante indietro di metà della sua larghezza, centrando effettivamente il pulsante */
}

.md-animation-button:hover{
  color: #cd171a;
  border-bottom: 2px solid black;
  font-size: 17px;
}

/* .md-animation-button:active {
  background-color: #f5f5f5;
  transform: translateZ(1px); 
} */


.md-animation-button .play-icon {
  display: inline-block;
  width: 0;
  height: 0;
  color:#cd171a;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 14px solid #cd171a;
  margin-left: 15px;
}

/* .md-hotspot{
  background-color: white;
  border-radius: 25px;
  width: 30px;
  height: 30px;
  background-size: contain;
  fill: white;
}

.md-hotspot-2{
  background-color: white;
  border-radius: 25px;
  width: 30px;
  height: 30px;
  
  fill: white;
  --min-hotspot-opacity: 1;
} */

.md-hotspot-2 {
  width: 20px;
  height: 20px;
  border: none;
  align-items: center;
}

.lottie-icon{
  position: absolute;
  height: 20px;
  width: 20px;
  align-items: center;
  background-position: center center;

}

.pulse {
  animation: pulseAnimation 1.5s infinite;
}

/* @keyframes pulseWave {
  0% {
      transform: scale(1);
      opacity: 0.7;
    }
    50% {
      transform: scale(1.1);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0.7;
    }
}
 */
.pulsing-circle {
  z-index: 999;
  display: flex;
  background-position: center center;
  align-items: center; 
  justify-content: center;
  padding: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: radial-gradient(circle, #cd171a, #cd171a, #bc2f32);
  background-size: 150% 150%;
  animation: pulseWave 2s infinite;
  border-color: transparent;
  --min-hotspot-opacity: 1;
  background-color: transparent;
  color: white;
  font-weight: bold;
}

.pulsing-circle-text{
  color: white;
  width: 15px;
  height: 15px;
  left: -7px;
  top: -7px;
  background-color: black;
  border-radius: 50%;
  border: none;
}
@keyframes pulseWave {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50%{
      transform: scale(1.5);
      opacity: 0.50;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}


.md-icon-lens-container{
  display: flex;
  justify-content: space-between;
  padding: 10px;
  padding-right: 20px;
}
.md-icon-lens{
  width: 50px;
  height: 50px;
  background-size: contain;
  filter: invert(100%);
  background-repeat: no-repeat;
}


.div-info-container{
  background-color: #00000060;
  width: 650px;
  top: 50%;
  left: 50%;    
  padding: 20px;
  padding-left: 30px;
  padding-right: 30px;
  text-align: right;
  transform: translateX(-50%); 
  align-items: center;
  display: flex;
  position: absolute;
  color: white;
  /* opacity: 1; Opacità iniziale a piena visibilità */
  z-index: 100;
}

.div-info-container-one{
  background-color: #00000060;
  width: 650px;
  top: 50%;
  left: 50%;    
  padding: 20px;
  padding-left: 30px;
  padding-right: 30px;
  text-align: right;
  transform: translateX(-50%); 
  align-items: center;
  display: flex;
  position: absolute;
  color: white;
  /* opacity: 1; Opacità iniziale a piena visibilità */
  z-index: 100;
  visibility: visible;
}

.div-info-container-two{
  background-color: #00000060;
  width: 650px;
  top: 50%;
  left: 10%;    
  padding: 20px;
  padding-left: 30px;
  padding-right: 30px;
  text-align: right;
  align-items: center;
  display: flex;
  position: absolute;
  color: white;
  /* opacity: 1; Opacità iniziale a piena visibilità */
  z-index: 100;
  visibility: visible;
}

.div-info-container-three{
  background-color: #00000060;
  width: 650px;
  top: 60%;
  left: 50%;    
  padding: 20px;
  padding-left: 30px;
  padding-right: 30px;
  text-align: right;
  align-items: center;
  display: flex;
  position: absolute;
  color: white;
  transform: translate(-50%);
  /* opacity: 1; Opacità iniziale a piena visibilità */
  z-index: 100;
  visibility: visible;
  transition: opacity 2s ease, transform 2s ease; /* Aggiunta della transizione */

}

.slide-in-start {
  opacity: 0;
  transform: translateX(-100%);
}

.slide-in-end {
  opacity: 1;
  transform: translate(-50%, 0);
}
/* styles.module.css o il tuo file CSS */

.md-info-lens{
  text-align: right;
}
.info-div-lens-icon{
  width: 200px;

}
.md-icon-lens-title{
  color: white;
  font-size: 12px;
}


.info-div-lens {
  width: 350px;
  font-size: 18px;
  font-weight: 400;
  padding-left: 20px;
  padding-top: 20px;
  color: white;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  vertical-align: center;
  /* 2. Applica l'animazione */
}

.info-div-lens-two {
  font-size: 18px;
  font-weight: 400;
  padding-top: 20px;
  color: white;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  vertical-align: center;
  text-align: center;
  padding-bottom: 20px;
  /* 2. Applica l'animazione */
}

.div-info-container-one-hidden{
  display: none;
}


@media (max-width: 768px) {


.div-info-container-one{
  background-color: #00000060;
  width: 350px;
  top: 20px;
  /* left: 50%; */
  padding: 20px;
  /* padding-left: 30px; */
  /* padding-right: 30px; */
  text-align: right;
  transform: translateX(-50%);
  align-items: center;
  display: flex;
  position: absolute;
  color: white;
  z-index: 100;
  visibility: visible;
}

.div-info-container-two{
background-color: #00000060;
width: 350px;
top: 20px;
/* left: 50%; */
padding: 20px;
/* padding-left: 30px; */
/* padding-right: 30px; */
text-align: right;
transform: translateX(-40%);
align-items: center;
display: flex;
position: absolute;
color: white;
z-index: 100;
visibility: visible;
}

.div-info-container-three{
background-color: #00000060;
width: 350px;
top: 20px;
/* left: 50%; */
padding: 20px;
/* padding-left: 30px; */
/* padding-right: 30px; */
text-align: right;
transform: translateX(-50%);
align-items: center;
display: flex;
position: absolute;
color: white;
z-index: 100;
visibility: visible;
}

.md-icon-lens{
width: 30px;
  height: 30px;
  background-size: contain;
  filter: invert(100%);
  background-repeat: no-repeat;
}

.info-div-lens{
width: 350px;
font-size: 12px;
font-weight: 400;
/* padding-left: 20px; */
padding-top: 20px;
color: white;
font-family: Verdana, Geneva, Tahoma, sans-serif;
vertical-align: center;
}
}




/*NEW STYLE*/
/* Common Styles for the Container */
.div-info-container {
  background-color: #00000060;
  width: 650px;
  padding: 20px 30px;
  text-align: right;
  align-items: center;
  display: flex;
  position: absolute;
  color: white;
  z-index: 100;
  /* Transition for opacity and transform */
  transition: opacity 2s ease, transform 2s ease;
}

/* Step 1 Container */
.div-info-container-step-1 {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the container */
}

/* Step 2 Container */
.div-info-container-step-2 {
  top: 50%;
  left: 10%;
  transform: translateY(-50%); /* Center vertically */
}

/* Step 3 Container */
.div-info-container-step-3 {
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Hidden Container (optional, if you need to hide containers) */
.div-info-container-hidden {
  display: none;
}

/* Icon Container */
.md-icon-lens-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 10px 10px;
}

/* Icon Styles */
.md-icon-lens {
  width: 50px;
  height: 50px;
  background-size: contain;
  filter: invert(100%);
  background-repeat: no-repeat;
}

/* Icon Text */
.md-info-lens {
  text-align: right;
}

/* Icon Title */
.md-icon-lens-title {
  color: white;
  font-size: 12px;
}

/* Info Text */
.info-div-lens {
  width: 350px;
  font-size: 18px;
  font-weight: 400;
  padding-left: 20px;
  padding-top: 20px;
  color: white;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  vertical-align: center;
}

/* Additional Info Text for Step 3 */
.info-div-lens-two {
  font-size: 18px;
  font-weight: 400;
  padding-top: 20px;
  color: white;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  vertical-align: center;
  text-align: center;
  padding-bottom: 20px;
}

/* Pulsing Hotspot Styles */
.pulsing-circle {
  z-index: 999;
  display: flex;
  background-position: center center;
  align-items: center; 
  justify-content: center;
  padding: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: radial-gradient(circle, #cd171a, #cd171a, #bc2f32);
  background-size: 150% 150%;
  animation: pulseWave 2s infinite;
  border-color: transparent;
  --min-hotspot-opacity: 1;
  background-color: transparent;
  color: white;
  font-weight: bold;
}

/* Pulse Animation */
@keyframes pulseWave {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Animation Button */
.md-animation-button {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  z-index: 10;
  position: absolute;
  bottom: 40px;
  border: 0px;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid #cd171a;
  text-transform: uppercase;
  left: 50%;
  transform: translateX(-50%);
}

.md-animation-button:hover {
  color: #cd171a;
  border-bottom: 2px solid black;
  font-size: 17px;
}

/* Play Icon in Button */
.md-animation-button .play-icon {
  display: inline-block;
  width: 0;
  height: 0;
  color: #cd171a;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 14px solid #cd171a;
  margin-left: 15px;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .div-info-container {
    width: 350px;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 20px;
  }

  .div-info-container-step-1,
  .div-info-container-step-2,
  .div-info-container-step-3 {
    top: 20px;
  }

  .md-icon-lens {
    width: 30px;
    height: 30px;
  }

  .info-div-lens {
    width: 100%;
    font-size: 12px;
    padding-top: 20px;
  }
}
