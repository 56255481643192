 .colum-element{
    display: flex;
    flex-direction: column;
    padding: 10px;
}

/*
.restore-button{
    width: 15px;
    height: 15px;
    margin-top: auto;
    background-image: url("../../../assets/ico/icona-reset.svg");
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: 10px;
    margin-bottom: 10px;
}

.remove-button{
    width: 15px;
    height: 15px;
    margin-top: auto;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: 10px;
    margin-bottom: 10px;
    background-image: url("../../../assets/ico/icona-cestino.svg");
} */